import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import { Text, Button } from "@ui";
import { Select } from "@forms";
import { t } from "@translations";

const IndexFilter = ({
  categories,
  categoryValue,
  topics,
  topicValue,
  indexName,
}) => {
  const [{ breakpoints }, dispatch] = useAppState();

  function setCategory(category) {
    if (category !== categoryValue) {
      dispatch({
        type: "setCategory",
        indexName,
        category,
        topic: "",
      });
    }
  }

  function setTopic(topic) {
    if (topic !== topicValue) {
      dispatch({
        type: "setTopic",
        indexName,
        topic,
        category: "",
      });
    }
  }

  const { sm } = breakpoints;

  return (
    <div className="sm:flex sm:items-center py-5 border-b-2 border-gray-1">
      <Text preset="label" className="hidden sm:inline font-bold mr-3">
        {t("Filter by category")}
      </Text>

      {!sm && (
        <Select
          name="category"
          label={t("Filter by category")}
          value={categoryValue}
          options={categories}
          inline={false}
          onChange={event => setCategory(event.target.value)}
          className="w-full"
        />
      )}

      {sm &&
        categories.map(({ title, id }) => (
          <Button
            key={id}
            type="button"
            text={title}
            preset={categoryValue === id ? "blue" : "lightGray"}
            size="xs"
            className="font-bold mr-3"
            onClick={() => setCategory(id)}
            applySentenceCase={false}
          />
        ))}

      {topics.length > 0 && (
        <Select
          name="topic"
          label={t("Filter by topic")}
          value={topicValue}
          options={topics}
          inline={false}
          onChange={event => setTopic(event.target.value)}
          className="w-full mt-4 sm:mt-0 sm:w-auto sm:ml-auto"
        />
      )}
    </div>
  );
};

IndexFilter.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  categoryValue: PropTypes.string,
  topicValue: PropTypes.string,
  indexName: PropTypes.string.isRequired,
};

IndexFilter.defaultProps = {
  categories: [],
  topics: [],
  categoryValue: "",
  topicValue: "",
};

export default IndexFilter;
