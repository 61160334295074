import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import SingleCard from "../singles/SingleCard";
import SingleCardAlt from "../singles/SingleCardAlt";
import { Paginator } from "@common";
import { imageProps } from "@ui";

const IndexList = ({ items: _items, state, type }) => {
  const [{ breakpoints }, dispatch] = useAppState();
  const { category, topic, page } = state;

  const filteredItems = _items
    .filter(
      ({ categories = [] }) =>
        category === "" ||
        categories.findIndex(({ id }) => id === category) > -1
    )
    .filter(({ topic: _topic }) => topic === "" || topic === _topic);

  const ctaText = {
    blog: "read the post",
    news: "read the article",
    videos: "watch the video",
    research: "view the research",
  };

  const { lg } = breakpoints;
  const forceSmall = idx =>
    (lg && type === "news" && idx % 4 !== 0) || (lg && type === "videos");
  let limit = 9;
  if (lg && type === "news") {
    limit = 12;
  }
  if (type === "research") {
    limit = 8;
  }

  const renderPosts = items => (
    <div className="flex flex-wrap -mx-3">
      {items.map((uid, idx) => {
        const { title, date, summary, image, categories } = filteredItems.find(
          item => item.uid === uid
        );

        if (type === "research") {
          return (
            <SingleCardAlt
              key={uid}
              type={type}
              slug={uid}
              title={title}
              image={image}
              ctaText={ctaText[type]}
              className="w-full md:w-1/2"
            />
          );
        }

        return (
          <SingleCard
            key={uid}
            type={type}
            slug={uid}
            title={title}
            summary={summary}
            date={date}
            image={image}
            categories={categories}
            ctaText={ctaText[type]}
            forceSmall={forceSmall(idx)}
            className={forceSmall(idx) ? "w-1/3" : "w-full"}
          />
        );
      })}
    </div>
  );

  return (
    <Paginator
      items={filteredItems.map(({ uid }) => uid)}
      page={page}
      limit={limit}
      loadMore={() => dispatch({ type: "incrementIndexPage", indexName: type })}
      render={renderPosts}
    />
  );
};

IndexList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      summary: PropTypes.shape({
        html: PropTypes.string.isRequired,
      }),
      firstPublicationDate: PropTypes.string,
      image: imageProps.props,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  state: PropTypes.shape({
    category: PropTypes.string.isRequired,
    topic: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
  }).isRequired,
  type: PropTypes.oneOf(["blog", "news", "videos", "research"]).isRequired,
};

export default IndexList;
