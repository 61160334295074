import React, { useEffect } from "react";
import { useAppState } from "@state/state";
import { IndexHero, IndexFilter, IndexList } from "@modules/indexes/index";
import {
  useVideoIndexData,
  useAllVideosData,
  useAllContentTopicData,
} from "@staticQueries";
import { SEO, PreFooter } from "@common";
import { Section, Container } from "@ui";

import { t } from "@translations";

/* eslint react/prop-types: 0 */

const VideoIndex = ({ pageContext }) => {
  const { meta, hero, prefooter } = useVideoIndexData();
  const { categories, topics } = pageContext;
  const [{ indexPages }, dispatch] = useAppState();
  const { videos } = indexPages;

  const { topics: _topics } = useAllContentTopicData();

  useEffect(() => {
    dispatch({
      type: "setHeaderTheme",
      headerTheme: {
        bgColor: "blue",
        showSearch: true,
        lockup: {
          size: "small",
          color: "blue",
        },
      },
    });
  }, []);

  const { videos: _videos } = useAllVideosData();

  const categoryFilter = [
    {
      title: t("all"),
      id: "",
    },
    ...categories,
  ];

  const topicFilter = [
    {
      title: t("all"),
      id: "",
    },
    ...topics.map(topic => {
      const mapped = _topics.find(_topic => _topic.uid === topic);
      return {
        title: mapped.name,
        id: mapped.uid,
      };
    }),
  ];

  return (
    <>
      <SEO {...meta} />
      <IndexHero heading={hero.heading} description={hero.description} />

      <Section hasPaddingTop={false} hasPaddingBottom={false}>
        <Container>
          <IndexFilter
            categories={categoryFilter}
            categoryValue={videos.category}
            topics={topicFilter}
            topicValue={videos.topic}
            indexName="videos"
          />
        </Container>
      </Section>

      <Section size="sm">
        <Container>
          <IndexList items={_videos} state={videos} type="videos" />
        </Container>
      </Section>

      <PreFooter {...prefooter} />
    </>
  );
};

export default VideoIndex;
