import React from "react";
import PropTypes from "prop-types";
import { Text, RichText, RandomShapes, Section, Container } from "@ui";

/* eslint react/prop-types: 0 */

const IndexHero = ({ heading, description }) => {
  return (
    <Section size="sm" className="bg-blue">
      <RandomShapes />
      <Container className="relative z-10 pt-4">
        <div className="text-center mt-12 lg:mt-24 max-w-sm mx-auto text-white">
          <Text preset="h1">{heading}</Text>

          {description && (
            <RichText html={description.html} className="RichText--sans mt-6" />
          )}
        </div>
      </Container>
    </Section>
  );
};

IndexHero.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }),
};

IndexHero.defaultProps = {
  description: null,
};

export default IndexHero;
